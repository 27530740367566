<template>
	<div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<BHLoading :show="loading" />
		<a-card>
			<h2>DocuSign Integration</h2>
			<div class="mt-4">
				<span style="color: var(--med-gray)">Status: </span>
				<span v-if="integrated.active && !removedIntegration">Connected</span>
				<span v-else class="text-danger">Not Linked to DocuSign</span>
			</div>
			<div v-if="integrated.active &&
				integratedUser &&
				!removedIntegration
				">
				<div>
					<span>
						<span style="color: var(--med-gray)">Name:
						</span>
						<span class="text-success">{{
							integratedUser.name
						}}</span>
					</span>
					<span class="text-success ml-1" style="position: relative; top: 5px">
						<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" style="width: 20px; height: 20px">
							<path fill-rule="evenodd"
								d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
								clip-rule="evenodd" />
						</svg>
					</span>
				</div>
				<div>
					<span style="color: var(--med-gray)">Email: </span>
					<span>{{ integratedUser.email }}</span>
				</div>
			</div>
			<hr />

			<div class="mt-4">
				<a-button @click="disconnectDocusign" type="danger" v-if="integrated.active && !removedIntegration"
					size="large" icon="minus">Remove Integration</a-button>
				<a :href="integrated.connectUrl" v-else-if="integrated.connectUrl">
					<a-button type="primary" size="large" icon="link">Integrate with DocuSign</a-button>
				</a>
			</div>
		</a-card>

	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'

export default {
	components: {
		BHLoading,
	},
	data() {
		return {
			loading: false,
			removedIntegration: false,
		}
	},

	computed: {
		integratedUser() {
			return (this.integrated && this.integrated.userInfo) || false;
		},
		integrated() {
			return this.$store.state.appData.vendors.docusign;
		},
	},

	methods: {
		async disconnectDocusign() {
			let self = this;
			this.$confirm({
				title: `Disconnect DocuSign Integration`,
				content: (h) => (
					<div>Do you want to remove DocuSign Integration?</div>
				),
				okText: "DISCONNECT",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: async () => {
					self.loading = true;
					try {
						await self.$api.delete(`/vendors/:instance/${this.integrated.id}`);
						self.removedIntegration = true;
						self.$store.commit('UPDATE_DOCUSIGN')
						self.$message.success('DocuSign Integration removed successfully.')
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, "Error while disconnecting DocuSign Integration. Please try again!"))
						}
					}
					self.loading = false;
				},
			});
		}
	},
}

</script>
