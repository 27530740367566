<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<h4 class="mb-3">Settings</h4>
		<SettingsTab @menuClick="menuClick" :menuList="menuList" :activeTab="activeTab" width="180">
			<DocuSign v-if="activeTab === 'integration'" />
		</SettingsTab>
	</div>
</template>

<script>
import SettingsTab from 'bh-mod/components/common/SettingsTab'
import DocuSign from "./DocuSign.vue"

export default {
	components: {
		SettingsTab,
		DocuSign
	},
	data() {
		return {
			activeTab: 'integration',
			menuList: [
				{
					label: 'General Settings', id: 'sub1', children: [{ label: 'DocuSign Integration', id: 'integration' }]
				}
			],
		};
	},
	methods: {
		menuClick(data) {
			this.activeTab = data;
		}
	},
};
</script>
<style>
</style>
